$main-color-dark: #002E34;

.singleProduct {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 25px;
    gap: 15px;
    justify-content: space-evenly;
    margin-top: 50px;
    margin-bottom: 120px;
}

.singleProduct>div {
    position: relative;
}

.absoluteSubImage {
    position: absolute;
    bottom: -25%;
    left: 0;
    display: flex;
}

.mainImage {
    width: 100%;
    max-width: 500px;
    height: 1/1;
    border-radius: 15px;
}

.subPhoto {
    margin: 5px;
    border-radius: 5px;
}

.productCategory {
    font-size: 24px;
    padding: 8px 14px;
    background-color: rgba(79, 84, 239, 0.795);
    border-radius: 5px;
}

.productName {
    font-size: 40px;
    padding: 8px 0;
    margin-bottom: 5px;
}

.categoryName {
    align-items: center;
    display: flex;
    gap: 0 25px;
}

.singleProductInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    gap: 15px;

    p {
        max-width: 450px !important;
    }
}

.calculateCounter {
    height: 40px;
    display: flex;
    align-items: center;
    padding-inline: 5px;
    justify-content: space-between;
    width: 150px;
    background-color: dodgerblue;
    border-radius: 5px;

    strong {
        color: white;
    }

    i {
        padding: 8px;
        background-color: #fff;
        border-radius: 5px;
        color: #000 !important;
    }
}


.products {
    height: 335px;
    display: flex;
    overflow: scroll;
    gap: 25px;
    padding-inline: 25px;

    .productCard {
        box-sizing: border-box;
        padding: 10px;
        border-radius: 15px;
        border: 2px solid rgb(5, 3, 3);
        display: flex;
        flex-direction: column;
        gap: 25px;

        img {
            width: 210px;
            aspect-ratio: 1/1;
            border-radius: 15px;
        }

        h3 {
            color: #000;
        }
    }
}

@media only screen and (max-width: 865px) {
    .singleProduct {
        margin-bottom: 45px;
    }

    .absoluteSubImage {
        position: absolute;
        bottom: -60px;
        left: 0;
        display: flex;
    }

    .subPhoto {
        width: 50px;
        aspect-ratio: 1/1;
        margin: 5px;
        border-radius: 5px;
    }

    .products {
        height: 200px;
        gap: 15px;

        .productCard {
            padding: 5px;
            gap: 15px;

            img {
                width: 140px;
            }
        }
    }
}

@media only screen and (max-width: 635px) {
    .singleProduct {
        flex-direction: column;
        margin-bottom: 0;
    }

    .singleProductInfo {
        margin-top: 55px;
    }
}