$main-color-dark: #002E34;

.singleProduct {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 25px;
    gap: 15px;
    justify-content: space-evenly;
}

.mainImage {
    width: 100%;
    max-width: 500px;
    height: 1/1;
    border-radius: 15px;
}

.singleProductInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    gap: 15px;

    p {
        max-width: 450px !important;
    }
}

.calculateCounter {
    height: 40px;
    display: flex;
    align-items: center;
    padding-inline: 5px;
    justify-content: space-between;
    width: 150px;
    background-color: dodgerblue;
    border-radius: 5px;

    strong {
        color: white;
    }

    i {
        padding: 8px;
        background-color: #fff;
        border-radius: 5px;
        color: #000 !important;
    }
}


@media only screen and (max-width: 865px) {
    .singleProduct {
        margin-bottom: 45px;
    }
}

@media only screen and (max-width: 635px) {
    .singleProduct {
        flex-direction: column;
    }

    .singleProductInfo {
        margin-top: 0px;
        margin-bottom: 15px;
    }
}