.allproducts .swiper {
  width: 100%;
  min-height: 470px;
}

.allproducts .swiper-slide {
  text-align: left;
  font-size: 18px;
  background: #fff;
  width: 315px !important;
  height: 420px !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #cacaca;
}

.allproducts .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.allproducts .swiper {
  width: 100%;
  height: 100%;
}

.allproducts .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// .loader {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 15;
//   min-height: 230vh;
//   width: 100vw;
//   background-color: #e0cdcda4;
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   div {
//     margin-top: 40vh;
//     width: 100px;
//     height: 100px;
//     border-bottom: 5px solid black;
//     border-radius: 50%;
//     animation: loader 1s linear infinite forwards;
//   }
// }
// @keyframes loader {
//   to {
//     transform: rotate(360deg);
//   }
// }
