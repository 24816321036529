$main-color-dark: #002E34;

.search__heading {
  margin-top: 50px;
  background-color: #fff;
  text-align: center;
  padding-top: 50px;

  h2 {
    font-size: 30px;
    font-weight: 500;
  }
}

.main__search {
  background-color: #fff;
  min-height: 100px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.search__wrapper {
  display: flex;
  width: 100%;
  gap: 0 20px;
  margin: 0 40px;
  height: 40px;
  align-items: center;
  border: 2px solid $main-color-dark;
  padding-left: 20px;
  overflow: hidden;

  svg {
    font-size: 30px;
  }
}

.search__wrapper form {
  display: flex;
  width: 100%;
  gap: 0 20px;
  height: 40px;
}

.search__input {
  flex: 1;
  border: none;
  font-size: 20px;
  outline: none;
}

.search__wrapper form button {
  background-color: $main-color-dark;
  color: #fff;
  border: none;
  padding-inline: 30px;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    opacity: .8;
    transition: 0.3s ease-in-out;
  }
}

.search__results {
  position: absolute;
  top: 100px;
  background-color: #fff;
  border: 2px solid $main-color-dark;
  box-shadow: 0px 20px 20px rgb(231, 230, 230);
  border-radius: 10px;
  min-height: 200px;
  max-height: 350px;
  overflow-y: scroll;
  width: calc(100% - 40px);
  margin: 0 20px;
  z-index: 2;
  padding: 10px;
  box-sizing: border-box;
}

.product__img {
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    color: #002E34;
  }

  img {
    border: 1px solid $main-color-dark;
    height: 60px;
    width: 60px;
  }
}

.result__nav {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  div {
    display: flex;
    gap: 40px;
  }
}

.input__txt {
  background-color: #dddddd;
  padding: 8px;
  border-radius: 5px;
  font-weight: 300;
}

.product__card__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px 15px;
  border: 1px solid $main-color-dark;
  margin: 10px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  strong {
    color: #5bb85b;
  }
}

.nothing {
  display: none;
}