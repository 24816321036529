$main-color-dark: #002e34;

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.navbar__link {
  color: inherit;
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 0 10px;

  svg {
    font-size: 25px;
  }
}

.main__link {
  background-color: #fff;
  padding: 12px 24px;
  margin: 0 20px;
  color: $main-color-dark;
  font-weight: 600;
  border-radius: 5px;
  border: 5px solid transparent;
  transition: 0.5s;

  &:hover {
    border: 5px solid #fff;
    background-color: $main-color-dark;
    color: #fff;
  }
}

.main__link__dark {
  background-color: $main-color-dark;
  padding: 12px 24px;
  margin: 0 20px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  border: 5px solid transparent;
  transition: 0.5s;

  &:hover {
    border: 5px solid #002e34;
    background-color: #fff;
    color: $main-color-dark;
  }
}

@media screen and (max-width: 992px) {
  .navbar__link span {
    display: none;
  }

  .main__link {
    padding: 8px 14px;
  }

  .main__link__dark {
    padding: 8px 14px;
  }
}

.cardBtn__choose {
  background-color: #4361ee;
  align-items: center;
  border-radius: 10px;
  bottom: 10px;
  color: #fff;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  z-index: 9999;
}

.cardBtn__choose>svg {
  font-size: 23px;
  margin-bottom: 3px;
  margin-right: 10px;
}