.announcement .swiper {
  width: 100%;
  height: 50px;
}

.announcement .swiper-slide{
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.announcement .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
